<template>
  <div
    class="lobby"
    :class="{ 'mb-bg': (isMobile && isPotrait) || (isTablet && isPotrait) }"
  >
    <div
      class="d-flex flex-column justify-content-center"
      v-if="(isMobile && isPotrait) || (isTablet && isPotrait)"
    >
      <img
        src="@/assets/img/sateclogo.png"
        class="img-fluid mb-3"
        width="30%"
        alt="satec logo"
      />
      <img
        src="@/assets/img/lobby/text.png"
        class="img-fluid mb-4"
        alt="mobile lobby text"
      />
    </div>

    <img
      src="@/assets/img/lobby/lobby.png"
      class="bgimg"
      id="lobbyImage"
      alt="lobby image"
      usemap="#lobby"
    />

    <a
      href="javascript:void(0)"
      @click="logout()"
      class="logout"
      v-if="isUADesktop"
    >
      <img
        src="@/assets/img/lobby/buttons/logout.png"
        class="img-fluid"
        alt="logout"
      />
    </a>

    <!-- mobile btns -->

    <div v-if="isMobile || (isTablet && isPotrait)" class="my-3 mobilelinks">
      <div class="d-flex flex-column align-items-center">
        <span v-for="(mblink, index) in mblinks" :key="index">
          <router-link :to="mblink.path" v-if="mblink.path">
            <img
              :src="require(`../assets/img/lobby/${mblink.button}`)"
              class="img-fluid"
              :alt="`mobile button ${mblink.name}`"
            />
          </router-link>
          <a
            v-if="mblink.target"
            href="javascript:void(0)"
            data-toggle="modal"
            :data-target="`#${mblink.target}`"
          >
            <img
              :src="require(`../assets/img/lobby/${mblink.button}`)"
              class="img-fluid"
              :alt="`mobile button ${mblink.name}`"
            />
          </a>
          <a :href="mblink.link" target="_blank" v-if="mblink.link">
            <img
              :src="require(`../assets/img/lobby/${mblink.button}`)"
              class="img-fluid"
              :alt="`mobile button ${mblink.name}`"
            />
          </a>
          <a href="javascript:void(0)" @click="logout()" v-if="mblink.func">
            <img
              :src="require(`../assets/img/lobby/${mblink.button}`)"
              class="img-fluid"
              :alt="`mobile button ${mblink.name}`"
            />
          </a>
        </span>
      </div>
    </div>

    <!-- finger icons for desktop only -->
    <img
      src="@/assets/img/clickicon.png"
      class="clickImage"
      v-if="isUADesktop"
      id="about"
      :title="settings.hover_about_message"
      @click="openAboutModal"
    />
    <img
      src="@/assets/img/clickicon.png"
      class="clickImage"
      v-if="isUADesktop"
      id="sponsor"
      title="sponsor"
      data-toggle="modal"
      data-target="#sponsorModal"
    />
    <img
      src="@/assets/img/clickicon.png"
      class="clickImage"
      v-if="isUADesktop"
      id="programme"
      :title="settings.hover_programme_message"
      @click="reroute('/programme')"
    />
    <img
      src="@/assets/img/clickicon.png"
      class="clickImage"
      v-if="isUADesktop"
      id="speaker"
      :title="settings.hover_speakers_message"
      @click="openSpeakerModal"
    />
    <img
      src="@/assets/img/clickicon.png"
      class="clickImage"
      v-if="isUADesktop"
      id="advance"
      :title="settings.hover_art_message"
        data-toggle="modal"
        data-target="#hotspotFourModal"
    />
    <img
      src="@/assets/img/clickicon.png"
      class="clickImage"
      v-if="isUADesktop"
      id="4ir"
      :title="settings.hover_aerospace_message"
        data-toggle="modal"
        data-target="#hotspotThreeModal"
    />
    <img
      src="@/assets/img/clickicon.png"
      class="clickImage"
      v-if="isUADesktop"
      id="future"
      title="maintenance of future "
        data-toggle="modal"
        data-target="#hotspotOneModal"
    />
    <img
      src="@/assets/img/clickicon.png"
      class="clickImage"
      v-if="isUADesktop"
      id="green"
      :title="settings.hover_gt_message"
        data-toggle="modal"
        data-target="#hotspotTwoModal"
    />

    <!-- image map -->

    <map name="lobby">
      <area
        alt="sponsors"
        title="sponsors"
        href="javascript:void(0)"
        coords="1561,262,1552,539,1900,548,1918,228"
        shape="poly"
        data-toggle="modal"
        data-target="#sponsorModal"
      />
      <area
        alt="about"
        :title="settings.hover_about_message"
        coords="1566,159,1510,504,881,500,900,281"
        shape="poly"
        @click="openAboutModal"
      />
      <area
        alt="programme"
        :title="settings.hover_programme_message"
        coords="1506,762,1381,939,1759,1076,1891,1009,1875,817,1584,735"
        shape="poly"
        @click="reroute('/programme')"
      />
      <area
        alt="speakers"
        :title="settings.hover_speakers_message"
        coords="1117,776,1116,836,1312,912,1425,850,1448,726,1225,684"
        shape="poly"
        @click="openSpeakerModal"
      />
      <area
        alt="maintenance of the future"
        title="maintenance of the future"
        coords="308,698,577,659,603,792,602,841,481,880,384,873,328,810"
        shape="poly"
        data-toggle="modal"
        data-target="#hotspotOneModal"
      />
      <area
        alt="green technology"
        :title="settings.hover_gt_message"
        coords="637,652,646,741,693,790,865,762,863,658,807,627,716,622"
        shape="poly"
        data-toggle="modal"
        data-target="#hotspotTwoModal"
      />
      <area
        alt="4ir in aerospace"
        :title="settings.hover_aerospace_message"
        coords="582,887,591,1013,720,1055,842,1011,808,933,755,813,655,818"
        shape="poly"
        data-toggle="modal"
        data-target="#hotspotThreeModal"
      />
      <area
        alt="advanced aerospace technology"
        :title="settings.hover_art_message"
        coords="778,802,1033,726,1073,894,1013,939,905,946,851,924"
        shape="poly"
        data-toggle="modal"
        data-target="#hotspotFourModal"
      />
    </map>

    <!-- Speakers Modal-->

    <div
      class="modal fade"
      id="speakerModal"
      tabindex="-1"
      aria-labelledby="speakerModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body p-5">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>

            <h1 class="mb-5 popup-title">
              <span class="p-2">meet the speakers!</span>
            </h1>

            <div class="row justify-content-center">
              <div
                class="col-6 col-sm-4"
                v-for="speaker in speakers"
                :key="speaker.id"
              >
                <a
                  href="javascript:void(0)"
                  @click="reroute(`/speaker/${speaker.slug}`)"
                  v-if="speaker.linkenable"
                >
                  <img
                    :src="
                      require(`../assets/img/speaker/desktop/${speaker.thumbnail}`)
                    "
                    class="img-fluid"
                    alt="button"
                  />
                </a>
                <a
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#speakerGOHModal"
                  v-else
                >
                  <img
                    :src="
                      require(`../assets/img/speaker/desktop/${speaker.thumbnail}`)
                    "
                    class="img-fluid"
                    alt="button"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="speakerGOHModal"
      tabindex="-1"
      aria-labelledby="speakerGOHModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>

            <img
              src="@/assets/img/speaker/desktop/goh/goh.png"
              class="img-fluid"
              alt="goh"
              v-if="
                isUADesktop ||
                (isUAMobile && !isPotrait) ||
                (isUATablet && !isPotrait)
              "
            />
            <img
              src="@/assets/img/speaker/mobile/goh/goh.png"
              class="img-fluid"
              alt="goh"
              v-if="(isUAMobile && isPotrait) || (isUATablet && isPotrait)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- About Modal -->
    <div
      class="modal fade"
      id="aboutModal"
      tabindex="-1"
      aria-labelledby="aboutModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body p-5">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>

            <h1 class="mb-5 popup-title">
              <span class="px-3 py-2">about satec</span>
            </h1>

            <div class="row">
              <div
                class="col-6 col-sm-3"
                v-for="about in abouts"
                :key="about.id"
              >
                <img
                  :src="require(`../assets/img/${about.thumbnail}`)"
                  class="img-fluid"
                  :alt="about.title"
                  @click="openAboutImageModal(about)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- about image modal -->

    <div
      class="modal fade"
      id="aboutImageModal"
      tabindex="-1"
      aria-labelledby="aboutImageModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>
            <img
              :src="require(`../assets/img/about/${selectedAbout.dimg}`)"
              class="img-fluid"
              :alt="selectedAbout.title"
              v-if="selectedAbout.dimg && isUADesktop"
            />
            <img
              :src="require(`../assets/img/about/${selectedAbout.mimg}`)"
              class="img-fluid"
              :alt="selectedAbout.title"
              v-if="selectedAbout.mimg && isUAMobile"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Sponsor Modal -->
    <div
      class="modal fade"
      id="sponsorModal"
      tabindex="-1"
      aria-labelledby="sponsorModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>

            <img
              src="@/assets/img/sponsors/desktop.png"
              class="img-fluid"
              alt="sponsor image"
              v-if="isUADesktop"
            />
            <img
              src="@/assets/img/sponsors/mobile.png"
              class="img-fluid"
              alt="sponsor image"
              v-if="isUAMobile || isUATablet"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- closed message Modal -->
    <div
      class="modal fade"
      id="popupMessageModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="popupMessageModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-body p-5">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                  />
                </svg>
              </span>
            </button>
            <div class="text-center">
              <h3>{{ popup_message }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- hotspotOne Modal -->
    <div
      class="modal fade"
      id="hotspotOneModal"
      tabindex="-1"
      aria-labelledby="hotspotOneModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0 position-relative">
            <button
              type="button"
              class="close position-absolute close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <!-- <iframe
              src="https://www.pico.com/en/"
              style="border: 0px #ffffff none"
              name="hotspotOne"
              scrolling="no"
              frameborder="1"
              marginheight="0px"
              marginwidth="0px"
              height="600px"
              width="100%"
              allowfullscreen
            ></iframe> -->
            <img src="@/assets/img/streams/maintenance/bg.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- hotspotTwo Modal -->
    <div
      class="modal fade"
      id="hotspotTwoModal"
      tabindex="-1"
      aria-labelledby="hotspotTwoModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0 position-relative">
            <button
              type="button"
              class="close position-absolute close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <!-- <iframe
              src="https://www.pico.com/en/we-are-pico"
              style="border: 0px #ffffff none"
              name="hotspotTwo"
              scrolling="no"
              frameborder="1"
              marginheight="0px"
              marginwidth="0px"
              height="600px"
              width="100%"
              allowfullscreen
            ></iframe> -->
            <img src="@/assets/img/streams/maintenance/bg.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- hotspotThree Modal -->
    <div
      class="modal fade"
      id="hotspotThreeModal"
      tabindex="-1"
      aria-labelledby="hotspotThreeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0 position-relative">
            <button
              type="button"
              class="close position-absolute close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <!-- <iframe
              src="https://www.pico.com/en/work"
              style="border: 0px #ffffff none"
              name="hotspotThree"
              scrolling="no"
              frameborder="1"
              marginheight="0px"
              marginwidth="0px"
              height="600px"
              width="100%"
              allowfullscreen
            ></iframe> -->
            <img src="@/assets/img/streams/maintenance/bg.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- hotspotFour Modal -->
    <div
      class="modal fade"
      id="hotspotFourModal"
      tabindex="-1"
      aria-labelledby="hotspotFourModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0 position-relative">
            <button
              type="button"
              class="close position-absolute close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <!-- <iframe
              src="https://talentpavilion.sg/home"
              style="border: 0px #ffffff none"
              name="hotspotFour"
              scrolling="no"
              frameborder="1"
              marginheight="0px"
              marginwidth="0px"
              height="600px"
              width="100%"
              allowfullscreen
            ></iframe> -->
            <img src="@/assets/img/streams/maintenance/bg.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </div>
    <a
      href="https://go.gov.sg/satec2022survey"
      target="_blank"
      class="dfeedback"
      v-if="isUADesktop"
    >
      <img
        src="@/assets/img/feedback.png"
        class="img-fluid"
        alt="feedback icon"
      />
    </a>
  </div>
</template>

<script>
import ImageMap from "image-map";
import $ from "jquery";
import { mapState } from "vuex";
import UAParser from "ua-parser-js";
import Api from "@/assets/js/Api";
import Csrf from "@/assets/js/Csrf";
import util from "@/assets/js/util";
export default {
  name: "lobby",
  computed: {
    ...mapState(["speakers", "abouts", "settings"]),
  },
  data() {
    return {
      popup_message: "",
      selectedAbout: {},
      isDesktop: false,
      isMobile: false,
      isTablet: false,
      isPotrait: false,
      isUADesktop: false,
      isUAMobile: false,
      isUATablet: false,
      mblinks: [
        {
          name: "about",
          button: "buttons/about.png",
          target: "aboutModal",
        },
        {
          name: "programme",
          path: "/programme",
          button: "buttons/programme.png",
        },
        {
          name: "speakers",
          target: "speakerModal",
          button: "buttons/speakers.png",
        },
        {
          name: "maintenance of future",
          button: "buttons/future.png",
          target: "hotspotOneModal"
        },
        {
          name: "green technology",
          button: "buttons/green.png",
          target: "hotspotTwoModal"
        },
        {
          name: "4IR",
          button: "buttons/4IR.png",
          target: "hotspotThreeModal"
        },
        {
          name: "advanced aerospace technology",
          button: "buttons/advance.png",
          target: "hotspotFourModal"
        },
        {
          name: "sponsors",

          button: "buttons/sponsors.png",
          target: "sponsorModal",
        },
        {
          name: "feedback",
          link: "https://go.gov.sg/satec2022survey",
          button: "buttons/feedback.png",
        },
        {
          name: "logout",
          func: "logout",
          button: "buttons/logout.png",
        },
      ],
    };
  },
  methods: {
    logActivity(event, type) {
      util.auth_middleware(this, event, type);
    },
    openSpeakerModal() {
      // if (!this.settings.is_speakers_open) {
      //   this.popup_message = this.settings.closed_speakers_message;
      //   $("#popupMessageModal").modal("show");
      //   return false;
      // }
      $("#speakerModal").modal("show");
    },
    openAboutModal() {
      // if (!this.settings.is_about_open) {
      //   this.popup_message = this.settings.closed_about_message;
      //   $("#popupMessageModal").modal("show");
      //   return false;
      // }

      $("#aboutModal").modal("show");
    },
    openAboutImageModal(about) {
      this.logActivity(`${about.title}`, "about click");
      this.selectedAbout = about;
      $("#aboutImageModal").modal("show");
    },
    reroute(path) {
      $("#speakerModal").modal("hide");
      // if (!this.routerMiddleware(path)) return;

      this.$router.push(path);
    },
    routerMiddleware(path) {
      return true;

      if (path == "/livestream/conference") {
        if (!this.settings.is_conference_open) {
          this.popup_message = this.settings.closed_conference_message;
          $("#popupMessageModal").modal("show");
          return false;
        }
      }
      if (path == "/livestream/maintenance_of_future") {
        if (!this.settings.is_mof_open) {
          this.popup_message = this.settings.closed_mof_message;
          $("#popupMessageModal").modal("show");
          return false;
        }
      }

      if (path == "/livestream/green_technology") {
        if (!this.settings.is_gt_open) {
          this.popup_message = this.settings.closed_gt_message;
          $("#popupMessageModal").modal("show");
          return false;
        }
      }

      if (path == "/livestream/advanced_aerospace_technology") {
        if (!this.settings.is_art_open) {
          this.popup_message = this.settings.closed_art_message;
          $("#popupMessageModal").modal("show");
          return false;
        }
      }

      if (path == "/livestream/4ir") {
        if (!this.settings.is_aerospace_open) {
          this.popup_message = this.settings.closed_aerospace_message;
          $("#popupMessageModal").modal("show");
          return false;
        }
      }

      if (path == "/programme") {
        if (!this.settings.is_programme_open) {
          this.popup_message = this.settings.closed_programme_message;
          $("#popupMessageModal").modal("show");
          return false;
        }
      }

      return true;
    },
    deviceType() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        this.isTablet = true;
      } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        this.isMobile = true;
      }
      this.isDesktop = true;
    },
    getOrientation() {
      var mql = window.matchMedia("(orientation: portrait)");

      if (mql.matches) {
        this.isPotrait = true;
      } else {
        this.isPotrait = false;
      }

      mql.addListener(function (m) {
        if (m.matches) {
          this.isPotrait = true;
        } else {
          this.isPotrait = false;
        }
      });
    },
    logout() {
      Csrf.getCookie().then((res) => {
        Api.post("log_out").then((res) => this.$router.push("/logout"));
      });
    },
  },
  mounted() {
    let self = this;
    $("#sponsorModal").on("show.bs.modal", function () {
      self.logActivity(`sponsor click`, "sponsor click");
    });
    $(window).bind("orientationchange", function (event) {
      location.reload(true);
    });

    let parser = new UAParser();

    let parser_result = parser.getResult();

    if (parser_result.device.type == "mobile") {
      this.isUAMobile = true;
    } else if (parser_result.device.type == "tablet") {
      this.isUATablet = true;
    } else {
      this.isUADesktop = true;
    }

    this.deviceType();
    this.getOrientation();

    ImageMap("img[usemap]", 300);

    function resize() {
      var windowHeight = $(".bgimg").height();
      var imageWidth = $(".bgimg").width();

      $("#about").css("top", 0.36 * windowHeight);
      $("#about").css("left", 0.6 * imageWidth);

      $("#sponsor").css("top", 0.26 * windowHeight);
      $("#sponsor").css("left", 0.85 * imageWidth);

      $("#programme").css("top", 0.76 * windowHeight);
      $("#programme").css("left", 0.75 * imageWidth);

      $("#speaker").css("top", 0.73 * windowHeight);
      $("#speaker").css("left", 0.65 * imageWidth);

      $("#advance").css("top", 0.78 * windowHeight);
      $("#advance").css("left", 0.45 * imageWidth);

      $("#4ir").css("top", 0.87 * windowHeight);
      $("#4ir").css("left", 0.32 * imageWidth);

      $("#future").css("top", 0.73 * windowHeight);
      $("#future").css("left", 0.2 * imageWidth);

      $("#green").css("top", 0.65 * windowHeight);
      $("#green").css("left", 0.34 * imageWidth);
    }

    if (this.isDesktop) {
      setTimeout(() => {
        resize();
      }, 300);
    }
  },
};
</script>

<style lang="scss" scoped>
.lobby {
  background-color: #bbbbbb;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  & .bgimg {
    width: 100%;
    height: 100%;
  }
  area {
    cursor: pointer;
  }
  & .clickImage {
    position: absolute;
    z-index: 100;
    height: auto;
    cursor: pointer;
    transition: ease-in-out 0.5s;
  }
  & .logout {
    position: fixed;
    top: 5%;
    right: 2%;
    width: 360px;
    height: auto;
    transition: ease-in-out 0.5s;
  }
  & .dfeedback {
    position: fixed;
    bottom: 2%;
    left: 2%;
    transition: ease-in-out 0.5s;
    & img {
      width: 80px;
    }
  }
  & .mobilelinks {
    max-height: 380px;
    height: 100%;
    overflow-y: auto;

    & div {
      height: 100%;
    }
  }
  & #speakerModal,
  #aboutModal {
    .modal-body {
      background: url("../assets/img/speaker/desktop/bg.png") no-repeat;
      background-position: center;
      background-size: cover;
      text-align: center;

      & img {
        cursor: pointer;
      }
    }
  }
  .close-btn{
    right: 2%;
    top: 2%;
  }
}

.mb-bg {
  padding: 10px;
  background: url(../assets/img/lobby/mlobby.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}

@media (min-width: 320px) and (max-width: 1024px) and (orientation: portrait) {
  .lobby {
    overflow: auto;

    & .bgimg {
      height: auto;
    }

    .clickImage {
      height: 60px;
    }
  }
}

@media (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
  .lobby {
    overflow: hidden;

    & .bgimg {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }

    .clickImage {
      height: 60px;
    }
  }
}
</style>
